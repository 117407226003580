export default {
  dataIterator: {
    rowsPerPageText: 'Αντικείμενα ανά σελίδα:',
    rowsPerPageAll: 'Όλα',
    pageText: '{0}-{1} από {2}',
    noResultsText: 'Δε βρέθηκαν αποτελέσματα',
    nextPage: 'Επόμενη σελίδα',
    prevPage: 'Προηγούμενη σελίδα'
  },
  dataTable: {
    rowsPerPageText: 'Γραμμές ανά σελίδα:'
  },
  noDataText: 'Χωρίς δεδομένα',
  carousel: {
    prev: 'הקודם חזותי',
    next: 'הבא חזותי'
  }
}
